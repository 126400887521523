@import '../../styles/common';

nav #marker-tests {
	position: absolute;
	left: 11px;
	height: 4px;
	width: 45px;
	background: #ffcb5c;
	bottom: -8px;
	transition: 0.5s;
	border-radius: 4px;
}

nav #marker-notification {
	position: absolute;
	left: 75px;
	height: 4px;
	width: 105px;
	background: #ffcb5c;
	bottom: -8px;
	transition: 0.5s;
	border-radius: 4px;
}

.lit-search-by-test-key {
	margin-top: 10px;
	margin-left: 10px;
	height: 100vh;
	background-color: #f0f9fc;
	padding: 10px;
	margin-top: 20px;
	border-radius: 25px 0px 0px 0px;
}
.lit-test-section-drop-down {
	margin-top: 40px;
	margin-left: 10px;
	width: 200px;
}

.notification-list-control-panel {
	margin: 20px auto;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-family: Arial, sans-serif;
	background-color: #f0f9fc;
	border-radius: 25px 0 0 0;
	padding: 10px;
}
