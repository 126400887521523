.lit-text-editor-heading {
	text-align: left;
}

.lit-text-editor-content {
	display: grid;
	justify-content: left;
	min-height: 400px;
}

.lit-post-notification-btn-div {
	margin-top: 50px;
}
.alert-box {
	position: fixed;
	bottom: 20px;
	right: -300px;
	color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 200px;
}

.alert-box.visible {
	right: 20px;
	opacity: 1;
}

.alert-box.success {
	background-color: #28a745;
}

.alert-box.error {
	background-color: #dc3545;
}

.icon {
	margin-left: 10px;
	font-size: 20px;
}

.lit-text-editor-input {
	margin: 10px 0px 10px 0px;
	width: 30%;
}
.lit-text-editor-input-switch {
	margin: 10px 0px 10px 0px;
	display: flex;
	margin: 20px 0px;
}

.lit-text-editor-input,
textarea,
input {
	margin-top: 5px;
}
