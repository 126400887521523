.switch {
	width: 52px;
	height: 24px;
	border-radius: 15px;
	cursor: pointer;
	position: relative;
	transition: background-color 0.3s ease;
}

.switch-on {
	background-color: #0f4256;
}

.switch-off {
	background-color: gray;
}

.toggle {
	width: 22px;
	height: 22px;
	background-color: white;
	border-radius: 50%;
	position: absolute;
	top: 1px;
	transition: transform 0.3s ease;
}

.toggle-on {
	transform: translateX(30px);
}

.toggle-off {
	transform: translateX(0px);
}
