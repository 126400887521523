@import '../../../styles/common';

.myClass-activity-table-cell {
	text-align: left;
	width: 1000px;
	justify-content: left;
	align-items: left;
	word-wrap: break-word;
	padding: 12px 5px;
	font-size: 16px;
	font-family: 'Lato';
	margin: 0 1px 0 1px;
}

.myClass_activity-table-header {
	text-align: left;
	width: 1000px;
	justify-content: left;
	align-items: left;
	word-wrap: break-word;
	padding: 12px 5px;
	font-size: 16px;
	font-family: 'Lato';
	color: white;
	margin: 0 1px 0 1px;
}

.lit-main-activity-contianer {
	background-color: rgba(241, 245, 247, 0.932);
	border-radius: 25px;
	margin-top: 8px;
	height: 70vh;
	overflow-x: auto;
	width: 100%;
	padding: 0px 10px;
}

.lit-myClass-activity-header-wrapper {
	display: flex;
	width: 100%;
}

.lit-input__field3 {
	margin-left: 10px;
}

.lit-myClass-activity-header-grid {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: $cyan;
	border-width: 2px 2px 0px 2px;
	color: black;
	justify-content: left;
	align-items: left;
	top: 0;
	position: sticky;
	border-radius: 10px 10px 0 0;
}

.lit-subTable_identifiers {
	display: flex;
	align-items: center;

	.lit-input-testCount-heading {
		float: right;

		margin-right: 10px;
	}
	.lit-input-testCount-info {
		float: right;

		margin-right: 15px;
		font-size: large;
		font-weight: bold;
	}
}

.lit-btn-activity-grid {
	margin-top: 20px;
}

.lit-new-test-activity {
	&__wrapper {
		width: 100%;
	}
}

.myClass_activity-expand-btn-cell {
	margin-top: 2px;
}

.lit_myClass-activity-table-wrapper {
	margin-top: 10px;
	background-color: $cyan;
	border-radius: 10px;

	width: 100%;
}

.lit-input__field {
	margin-right: 10px;
}

.lit-input-testType {
	display: flex;
	justify-content: space-between;
	border-radius: 25px;
}

.lit-activity-subtable-status-mark {
	margin-left: -5px;
}

.lit-test-activity-subtable-header {
	padding: 10px 5px;
	text-align: left;
}

.lit-test-activity-subtable-data {
	padding: 5px;
	text-align: left;
}

.lit-test-activity-subtable-row {
	background-color: $white;
}

.lit-test-activity-subtable-handler {
	padding: 5px;
	text-align: left;
	width: 130px;
}

.lit-list-user-activity-subtable-list {
	width: 100%;
	color: $white;
	margin-top: 10px;
	border-spacing: 0;
	background-color: $cyan;
	border-radius: 15px 15px 0px 0px;
	padding: 5px;

	td {
		color: $black;
	}
}
