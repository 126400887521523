.loader {
	border: 5px solid #f3f3f3;
	border-top: 5px solid #3498db;
	border-right: 5px solid #3498db;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	animation: spin 0.7s linear infinite;
	margin: 0 auto;
}
