.lit-page-title,
.lit-page-lead {
	max-width: 800px;
	animation: fade 0.8s;
}

.lit-page-title {
	margin: 0;
}

.lit-page-lead {
	margin: 0px;
	font-size: $p;
	color: $white;
}
