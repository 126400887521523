@import '../../styles/common';

.lit-all-tests {
	.month {
		margin: $space-lg 0 $space-xlg;

		th {
			text-align: left;
			// padding: 0 $space;
		}

		td {
			text-align: left;
		}
	}

	.score {
		margin: 0;
	}
	button {
		margin-left: 10px;
	}
}
