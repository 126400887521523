@import '../../../styles/common';

.lit-candidate-info {
	color: black;

	margin: auto;

	.lit-candidate-container {
		margin-top: 25px;
		display: inline-block;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: auto;
		border: $orange solid 5px;
		background: $offwhite;
		background-color: $offwhite;
		border-radius: 13px;
	}

	.lit-info__main-container {
		&__wrapper1 {
			float: left;

			width: 40%;
			height: auto;
			background: $orange;
			border: $orange 2px solid;
			border-radius: 0 25px 25px 0;
		}

		&__wrapper2 {
			margin-top: 2px;
			background: $offwhite;
			border-radius: 5px;
			padding: 0px 20px;
			font-size: 15px;
			width: 60%;
			height: 100%;
			float: right;
			color: black;
		}

		/* 	flex-wrap: wrap; */

		.lit-info__field {
			margin-top: -5px;
			padding: 0.5em;
			font-size: normal;
		}

		.lit-info__field-fifth {
			padding: 0px 20px;
		}

		.lit-info__field-text {
			/* border: solid 1px rgb(197, 206, 197); */
			color: black;
			font-size: normal;
			padding: 0px 20px;

			p:first-child {
				font-weight: bold;
			}
		}
	}
}

.lit-radio {
	margin-right: 12px;
	color: black;
	margin-left: -8px;
}

.lit-candidate-info-header {
	width: 100%;

	margin: auto;
	margin-top: 5px;
	padding-top: 6px;
	text-align: center;

	color: white;

	&__bolder {
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: lighter;
	}
}

.lit-candidate-label {
	color: rgb(255, 255, 255);
	font-family: 'Roboto', Arial, sans-serif;
	padding: 10px;
}

.lit-candidate-label-pin-key {
	color: rgb(255, 255, 255);
	font-family: 'Roboto', Arial, sans-serif;
	padding: 0.5em;
	background-color: $white;
	margin-bottom: 10px;
	border-radius: 10px;
	border: 3px solid $blue;
	text-align: center;
	margin: 0 5px 15px 5px;
}

.lit-candidate-label-gender {
	/* 	border: solid 1px red; */
	/* background-color: white; */
	margin-top: -8px;
	align-content: center;
	color: white;
	padding: 0.5em;

	.lit-options-gender {
		margin: 20px 0px 0px -8px;
		font-size: medium;
		color: black;
		height: 65%;
	}
}

.lit-candidate-label-grade {
	/* border: solid 1px red; */
	/* background-color: white; */
	align-content: left;
	padding: 0.5em;
	color: white;
}

input[type='date'] {
	background-color: transparent;
	border: none;
	outline: none;
	color: rgb(6, 6, 95);
	font-size: 16px;
	font-family: 'Roboto Mono', monospace;
	letter-spacing: 1px;
	margin: auto;
	display: block;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	min-height: 1.2em;
}

.lit-input__field-trinn {
	border: none;
	border-radius: 3px;
	padding: 10px 5px;
	font-size: 16px;
	display: block;
	font-family: 'Roboto', Arial, sans-serif;
	width: 100%;
	box-sizing: border-box;
}

input[type='text'] {
	font-size: 16px;
	font-family: 'Roboto', Arial, sans-serif;
}

input[type='email'] {
	font-size: 16px;
}

.lit-candidate-pin {
	color: black;
	font-size: large;
	letter-spacing: 1.5px;
	font-weight: bold;
}

.lit-btn-submit-from {
	margin-top: 50px;

	.bg-primary-dark {
		background-color: $lightGreen;
		border: solid lightGreen 2px;
		border-radius: 2px;
		font-size: 0.9rem;

		max-width: 200px;
	}

	.bg-primary-glow {
		background-color: rgb(15, 163, 82);
		border: solid white 2px;
		border-radius: 2px;
		font-size: 0.9rem;
		max-width: 200px;
		color: white;
		animation: glowing 1200ms 1;
	}
}

.lit-modal__continent {
	background: transparent;
	padding: 10px;
	max-width: 80%;
	margin: auto;
	margin: 1% auto;
	animation: slideUp 0.7s;
	color: white;
}

.lit-modal-candidate {
	/* background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	border: rgba(0, 0, 0, 0.5) solid 1px;
	border-top-left-radius: 20px;
	
	top: 0;
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: $zModal;
    animation: fade 0.5s; */

	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	height: 100vh;
	width: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	z-index: 80;
	animation: fade 0.5s;
}
