@import '../../styles/common';

.notification-main-div {
	display: flex;
	border-bottom: 1px solid #eee;
}
.notification-main-div-unread {
	display: flex;
	border-bottom: 1px solid #eee;
	background-color: #d6effb;
}
.notification-list-heading {
	margin: 0 0 20px 0;
	font-size: 24px;
	color: #333;
	text-align: left;
	margin-left: 10px;
}

.notification-list-main {
	list-style-type: none;
	padding: 0;
	margin: 0;
	font-family: 'Arial', sans-serif;
}

.notification-list-notification {
	display: flex;
	flex-direction: column;
	flex: 11;
	padding: 10px 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.notification-list-notification:last-child {
	border-bottom: none;
}

.notification-main-div:hover {
	background-color: #d6effb;
}

.notification-list-time {
	font-size: 12px;
	color: #999;
	margin-bottom: 5px;
}

.notification-list-subject {
	font-size: 16px;
	color: #333;
	margin-bottom: 5px;
	font-weight: bold;
}
.notification-list-summary {
	font-size: 15px;
	color: #333;
	margin-bottom: 5px;
	font-weight: 500;
}

.notification-list-notificationdetails {
	font-size: 14px;
	color: #666;
}

.notification-content {
	padding: 20px;
	text-align: center;
}

.notification-subject {
	font-size: 24px;
	color: #333;
	margin-bottom: 10px;
	font-weight: bold;
}
.notification-summary {
	font-size: 20px;
	color: #333;
	margin-bottom: 10px;
	font-weight: 800;
}

.notification-time {
	font-size: 14px;
	color: #888;
	margin-bottom: 20px;
}

.notification-details {
	font-size: 16px;
	color: #555;
	margin-bottom: 20px;
	text-align: left;
}

.button-container {
	display: flex;
	justify-content: center;
}

.confirm-button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.confirm-button:hover {
	background-color: #0056b3;
}

.notification-list-notification-unread {
	background-color: #d6effb;
	display: flex;
	flex: 11;
	flex-direction: column;
	padding: 10px 10px;
	border-bottom: 1px solid #eee;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.notification-list-buttons {
	margin-top: 30px;
}

.lit-text-editor-div {
	margin: 10px 0px 10px 0px;
	width: 60%;
}

.lit-text-editor-div,
textarea,
input {
	margin-top: 5px;
}
