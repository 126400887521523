@import '../../styles/common';

/* 

*/
.lit-users {
	margin-left: -58px;
	margin-top: -77px;
	padding-left: 3px;
	padding-bottom: 7px;

	nav {
		position: relative;
		display: flex;
	}

	nav a {
		position: relative;
		margin: 0 10px;
		font-size: 20px;
		color: #fff;
		font-family: 'Lato';
		font-weight: 600;
		text-decoration: none;
	}

	nav #marker-default {
		position: absolute;
		left: 11px;
		height: 4px;
		width: 78px;
		background: #ffcb5c;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	nav #marker-activity {
		position: absolute;
		left: 110px;
		height: 4px;
		width: 80px;
		background: #ffcb5c;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	nav #marker-summary {
		position: absolute;
		left: 208px;
		width: 88px;
		height: 4px;
		background: #ffcb5c;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	nav #marker-invoices {
		position: absolute;
		left: 315px;
		width: 114px;
		height: 4px;
		background: #ffcb5c;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	.register-form {
		max-width: 440px;
		margin: 0 auto;
	}
	.lit-input__field {
		border-radius: 20px;
	}
	.lit-Users_wrapper {
		display: flex;
		margin-top: 20px;

		.lit-dropdown_filter {
			margin-right: 10px;

			.lit-input__field {
				border-radius: 20px;
			}
		}
		.lit-input__field {
			border-radius: 20px;
		}
		.Select_group-function {
			margin-right: 10px;

			.lit-input__field {
				border-radius: 20px;
			}
		}

		.lit-input__field {
			margin-left: 5px;
			overflow: hidden;
			background-color: rgb(250, 248, 248);
			border: solid rgb(28, 138, 189);
			border-width: 0.1em;
		}

		.lit-input_serach-select-filters {
			margin-left: 5px;
			overflow: hidden;

			border: solid rgb(28, 138, 189);
			border-width: 0.1em;
		}

		.lit-input_searchUser {
			float: right;
			width: 300px;
			margin-left: 10px;
			margin-top: 20px;
		}

		.lit_crate_group-btn {
			margin-left: 10px;
		}
	}

	.lit_txtf_users {
		display: inline-block;
		font-size: 17px;
		width: 250px;
		height: 45px;
		margin-left: 5px;
		background-color: white;
		box-shadow: 3px 5px #888888;
	}

	.lit-input__dropDown {
		display: inline-block;
		margin-left: 5px;
		margin-right: 20px;
		border-radius: 2px;
		padding: 5px 10px;
		font-size: 14px;
		background: #fff;
	}

	.user-list {
		font-size: 14px;
		margin-top: $space-lg;
	}

	.lit-test-status__indicator {
		&.tiny_loggedIn {
			background-image: linear-gradient(45deg, #86be1d, #0ef052);
			height: 11px;
			width: 11px;
			margin-left: 2px;

			/* margin-top: 5px;
			float: right; */
		}

		&.tiny_loggedOut {
			background-image: linear-gradient(45deg, #e3ece3, #cfd6d3);
			height: 10px;
			width: 10px;
			margin-left: 2px;

			/* margin-top: 5px;
			
			float: right; */
		}

		&.tiny_blocked {
			background-image: linear-gradient(45deg, #f52c12, #ee211a);
			height: 10px;
			width: 10px;
			margin-left: 2px;

			/* margin-top: 5px;
			
			float: right; */
		}
	}

	.bg-primary3-red {
		background-color: $pink;
		color: black;
	}

	.bg-primary3 {
		background-color: rgb(71, 189, 243);
		color: black;
	}

	.bg-primary3:hover {
		background-color: rgb(71, 189, 243);
	}
}

.lit_check-input_label {
	margin-left: 5px;
}

.lit_btn_add-members {
	display: inline;
}

.lit-input-newgroup {
	margin-top: 20px;
}

.lit_create_new-group {
	margin-top: 20px;
}

.lit_crate_group-btn {
}

.lit_action-btn {
	display: flex;
}

.lit_copy_email-textarea {
	height: 600px;
	width: 100%;
}

.lit_flag_creatingGroup {
	transform: scale(1.2);
}

.lit_flag_removefromgroup {
	margin-left: 20px;
	transform: scale(1.2);
}

.user_checkbox2 {
	display: inline-block;

	width: 1.25em;
	height: 1.25em;
	background: #fff;
	border-radius: 4px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
	.checkbox-wrapper-13 input[type='checkbox'] {
		--active: #275efe;
		--active-inner: #fff;
		--focus: 2px rgba(39, 94, 254, 0.3);
		--border: #bbc1e1;
		--border-hover: #275efe;
		--background: #fff;
		--disabled: #f6f8ff;
		--disabled-inner: #e1e6f9;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 21px;
		outline: none;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 0;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
	}

	.checkbox-wrapper-13 input[type='checkbox']:after {
		content: '';
		display: block;
		left: 0;
		top: 0;
		position: absolute;
		transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
			opacity var(--d-o, 0.2s);
	}

	.checkbox-wrapper-13 input[type='checkbox']:checked {
		--b: var(--active);
		--bc: var(--active);
		--d-o: 0.3s;
		--d-t: 0.6s;
		--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
	}

	.checkbox-wrapper-13 input[type='checkbox']:disabled {
		--b: var(--disabled);
		cursor: not-allowed;
		opacity: 0.9;
	}

	.checkbox-wrapper-13 input[type='checkbox']:disabled:checked {
		--b: var(--disabled-inner);
		--bc: var(--border);
	}

	.checkbox-wrapper-13 input[type='checkbox']:disabled + label {
		cursor: not-allowed;
	}

	.checkbox-wrapper-13
		input[type='checkbox']:hover:not(:checked):not(:disabled) {
		--bc: var(--border-hover);
	}

	.checkbox-wrapper-13 input[type='checkbox']:focus {
		box-shadow: 0 0 0 var(--focus);
	}

	.checkbox-wrapper-13 input[type='checkbox']:not(.switch) {
		width: 21px;
	}

	.checkbox-wrapper-13 input[type='checkbox']:not(.switch):after {
		opacity: var(--o, 0);
	}

	.checkbox-wrapper-13 input[type='checkbox']:not(.switch):checked {
		--o: 1;
	}

	.checkbox-wrapper-13 input[type='checkbox'] + label {
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		margin-left: 4px;
	}

	.checkbox-wrapper-13 input[type='checkbox']:not(.switch) {
		border-radius: 7px;
	}

	.checkbox-wrapper-13 input[type='checkbox']:not(.switch):after {
		width: 5px;
		height: 9px;
		border: 2px solid var(--active-inner);
		border-top: 0;
		border-left: 0;
		left: 7px;
		top: 4px;
		transform: rotate(var(--r, 20deg));
	}

	.checkbox-wrapper-13 input[type='checkbox']:not(.switch):checked {
		--r: 43deg;
	}
}

.checkbox-wrapper-13 * {
	box-sizing: inherit;
}

.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
	box-sizing: inherit;
}

.lit-address_list {
	font-size: 20px;
}

.lit-main_textarea {
	width: 100%;
	line-height: 4px;
	height: 400px;
	padding: 20px;
	border-radius: 10px;
	border: 1px solid #181715;
	font: 20px Tahoma, cursive;
	transition: box-shadow 0.5s ease;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	background: $skyBlue;
	margin-bottom: 10px;
}

.lit-tests_textarea {
	margin-left: 50px;

	line-height: 4px;
	padding: 20px 20px 20px;
	border: none;
	font: 14px Tahoma, cursive;
	transition: box-shadow 0.5s ease;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	background: white;
}

//copied css goes under
.lit-tab-wrapper {
	display: flex;
	background-color: rgba(38, 126, 165, 255);
	padding-left: 6;
	height: 50px;
	margin-left: -2px;

	.tablink1 {
		display: flex;
		margin-left: -1px;
		position: relative;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		border: none;
	}

	.tablink1-active {
		position: relative;
		margin: 0 10px;
		font-size: 20px;
		color: #fff;
		font-family: 'Lato';
		font-weight: 600;
		text-decoration: none;
	}

	.tablink2 {
		display: flex;
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		position: relative;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		border: none;
	}

	.tablink2-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		width: 185.14;
		border: none;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}

	.tablink3 {
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		position: relative;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}

	.tablink3-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		width: 185.14;
		border: none;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}

	.tablink4 {
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		position: relative;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		border: none;
	}

	.tablink4-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		width: 185.14;
		border: none;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}

	.tablink1:hover {
		background-color: rgba(76, 144, 177, 255);
		border: none;
		color: white;
	}

	.tablink2:hover {
		background-color: $cyan;
		border: none;
		color: white;
	}

	.tablink3:hover {
		background-color: rgba(76, 144, 177, 255);
		border: none;
		color: white;
	}

	.tablink4:hover {
		background-color: rgba(76, 144, 177, 255);
		border: none;
		color: white;
	}

	.dormanttablink3 {
		margin-left: -1px;
		width: 814px;
		display: inline-block;
		background-color: rgba(38, 126, 165, 255);
		color: transparent;
		height: 100%;
		border: none;
		text-align: left;
		font-size: 22px;
	}
}

.lit-dynamic-users-tablerows {
	background-color: #fff;
	padding: 2px;
}

.lit-activity-table-container {
	background-color: white;
	padding: 10px 3px;
	border-width: 0px 1px 1px 1px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.lit-table_wrapper {
	margin-top: 15px;

	.lit-input__field3 {
		width: 200px;
		margin-top: 20px;
		margin-left: 5px;
		overflow: hidden;
		font-size: 13px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;

		background-color: #fff;
		height: 100%;
	}
}
.lit-activity_grid {
	display: flex;
	cursor: pointer;
	justify-content: left;
	max-height: 40px;

	color: #555555;
	font-size: 14px;

	.bg-primary6 {
		border: none;
	}
}
.lit-activity_grid:hover {
	cursor: pointer;
	max-height: 40px;
	background-color: hsl(210, 4%, 81%);
	color: #555555;
	.bg-primary6 {
		border: none;
		color: white;
	}
}

.lit-activity_grid-active {
	display: flex;
	justify-content: left;
	cursor: pointer;
	max-height: 40px;
	background-color: $orange;
	border-bottom: none;
	border-width: 1.5px 1.5px 0.5px 1.5px;
	color: #555555;
	font-size: 14px;
	top: 0;
	position: sticky;

	.bg-primary6 {
		border: none;
		color: black;
	}

	justify-content: center;
}

.myClass_table-cell {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}

.myClass_title_table-cell {
}

.lit_summary_table-cell {
	text-align: left;
	justify-content: left;
	align-items: left;
	margin-left: 30px;
	margin-right: auto;
	word-wrap: break-word;
	padding: 8px;
}

.myClass-subscription_table-cell {
	text-align: left;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	padding: 8px;
}

.lit-myClass_individual-test {
}

.lit_input-filters-wrapper-activity {
	margin-top: 20px;
	display: flex;
	height: 40px;
	margin-bottom: -20px;

	.lit-input__field2 {
		width: 300px;
		margin-left: 5px;
		overflow: hidden;
		font-size: 16px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;

		background-color: #fff;
		height: 100%;
	}

	.lit-input-userActivity_searchUser {
		height: 100%;
		float: right;
		width: 230px;
		margin-left: 10px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;
	}
}

.lit-activity__field {
	display: flex;
	justify-content: right;
	align-items: right;
}

.lit-summary-table_view {
	display: flex;
}

.tickmark {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
}

.crossmark {
	font-family: arial;
	font-weight: bold;
	color: red;
}

//css for summary
.lit-summary_grid {
	display: flex;
	cursor: pointer;
	justify-content: left;
	background-color: white;
	padding: 3px;

	color: black;

	.bg-primary6 {
		background-color: white;
		border: none;
		color: black;
	}
}

.myClass_table-new-subs-cell {
	margin-right: 190px;
}

.lit-summary_title_table-cell {
	text-align: left;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	padding: 8px;
	width: 1000px;
}

.lit-date_filter {
	display: flex;
	justify-content: top;
	min-width: 300px;
	align-items: top;
	margin-top: 20px;
	div {
		margin-right: 2px;
	}
	.lit-input_dateLabel {
		padding: 3px;
		justify-content: center;
		align-items: center;
	}

	padding: 3px;
}

.lit-conutry_picker {
	margin-left: 5px;
}

.lit-summary-table_wrapper {
	display: flex;
	flex-direction: column;
	background-color: #f0f9fc;
	border-radius: 25px 0 0 0;
	padding: 20px;
	margin-top: 20px;
}

.react-datepicker__month-container {
	width: 350px;
}

.react-datepicker__navigation--next {
	margin-right: 20px;
	width: 15px;
	height: 15px;
	/* border: none; */
	font-size: none;

	//
}

.react-datepicker__navigation--previous {
	width: 15px;
	height: 15px;
	/* border: none; */
	font-size: 1px;
	-webkit-text-emphasis: none;
	text-emphasis: none;
}

.react-datepicker__year-dropdown-container {
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	input {
	width: auto;
	margin-left: 3px;
}

.lit-test__header {
	text-align: left;
	font-weight: 300;
}
.it-test__col {
	text-align: left;
}

.lit-list-users-new-group-header {
	text-align: left;
	padding: 10px 5px;
}

.lit-list-users-new-group-col {
	text-align: left;
	padding: 10px 5px !important;
}

.lit-input-newgroup-name {
	margin-top: 15px;
	.lit-tf_search {
		height: 40px;
	}
	.lit-input__field {
		width: 200px;
		height: 40px;
		margin-left: -1px;
	}
}
