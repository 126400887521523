@import '../../styles/common';

.lit-tests-view {
	margin-left: -55px;
	margin-top: -77px;
	padding-bottom: 7px;
	width: auto;

	nav {
		position: relative;
		display: flex;
	}

	nav a {
		position: relative;
		margin: 0 10px 0px 5px;
		font-size: 20px;
		color: $white;
		font-family: 'Lato';
		font-weight: 600;
		text-decoration: none;

		&:active {
			color: $orange;
		}
	}

	nav #marker-default {
		position: absolute;
		left: 5px;
		height: 4px;
		width: 105px;
		background: $orange;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	nav #marker-mytests {
		position: absolute;
		left: 150px;
		height: 34px;
		width: 18%;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	nav #marker-mycandidates {
		position: absolute;
		left: 125px;
		width: 160px;
		height: 4px;
		background: $orange;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}

	nav #marker-myclasses {
		position: absolute;
		left: 302px;
		width: 115px;
		height: 4px;
		background: $orange;
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
}

.lit-myTests-scroller {
	margin-top: 15px;
	max-height: 93vh;
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 90vh;
	min-width: 70vw;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.lit-myTests-scroller::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.lit-tests-view__header {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		& {
			flex-direction: column;
		}
	}
}

.lit-tests-view__contant {
	background-color: $lightBlue;
	padding: 10px;
	margin-top: 20px;
	border-radius: 25px 0px 0px 0px;
}

.lit-radio {
	margin-right: 12px;
	color: black;
	margin-left: -8px;
}

.lit-input__field-language-inputs {
	border: solid 1px white;
	width: 40%;
	display: grid;
}

.lit-test__heading {
	color: $black;
	/* border: solid 1px red; */
	width: 100%;
}

.lit-modal__continent {
	background: transparent;
	padding: 10px;
	max-width: 70%;
	margin: 2% auto;
	animation: slideUp 0.7s;
	color: white;
}

.lit-input-tab-grid {
	margin-top: 27px;
	display: flex;
	flex-wrap: wrap;
}

.lit-custom-header_grid {
	padding-top: 4px;
	display: flex;
	/* 
	background-color: #0da9e9; */

	bottom: black;
	/* border: 1px solid; */
	height: 42px;
	font-family: 'Lato';
	color: white;
}

.lit-custom_grid {
	display: flex;
	background-color: $white;
	color: black;
	font-family: 'Lato';
	height: 51px;
}

.lit-custom_grid:hover {
	cursor: pointer;
	background-color: hsl(210, 4%, 81%);
	color: rgb(22, 94, 189);

	.bg-primary5 {
		color: rgb(22, 94, 189);
	}
}
.bg-primary-show {
	height: 35px;
	float: right;
	background-color: #6ec4e5;
	width: 150px;
	color: black;
	opacity: 0.9;
}

.bg-primary-show-active {
	height: 35px;
	float: right;
	background-color: $pink;
	width: 150px;
	color: black;
	opacity: 0.9;
}

.bg-primary-show:hover {
	opacity: 1;
}

.lit-custom_grid-active {
	display: flex;
	background-color: #ebeef1;
	color: black;
	font-family: 'Roboto', Arial, sans-serif;
	margin-top: 1px;
	transition: 0.5s;
	animation: fade 0.5s;
	cursor: pointer;
}

.lit-custom-header-wrapper {
	display: flex;
	width: 100%;
	float: left;
}

.custom_table-header-cell {
	display: flex;
	text-align: left;
	justify-content: left;
	width: 1000px;
	margin-top: 8px;
}

.custom_table-cell {
	display: flex;
	text-align: left;
	justify-content: left;
	width: 1000px;
	margin-top: 8px;
}

.lit_table-wrapper {
	margin-top: 0px;
}

.custom_checkBox_header-cell {
	display: flex;
	padding: 3px;
	justify-content: left;
	align-items: left;
}

.custom_checkBox-cell {
	display: flex;
	padding-left: 5px;
	justify-content: left;
	align-items: left;
	width: 200px;
	margin-top: 5px;
}

.custom_btn-cell {
	display: flex;
	text-align: right;
	justify-content: right;
	width: 1000px;
	margin-top: 3px;
	margin-right: 5px;

	.bg-primary5 {
		height: 35px;
		float: right;
		background-color: $cerulean;
		width: 120px;
		color: black;
	}

	.bg-primary5-active {
		height: 35px;
		/* float: right; */
		background-color: $pink;
		opacity: 0.9;
		width: 120px;
		color: black;
	}

	.bg-primary5-active:hover {
		opacity: 1;
	}
}

.lit-individual-test {
	display: flex;
	border-width: 0px 1px 1px 1px;
	background-color: white;
	min-height: 60px;
	padding: 5px;
	height: 100%;
	transition: 5s;
	animation: fade 0.5s;
	border-bottom-left-radius: 11px;
	border-bottom-right-radius: 11px;
	margin-bottom: 5px;
	.lit-list-user-list {
		margin-top: 10px !important;
	}
}

.lit-individual-test-not-available {
	background-color: $skyBlue;
	color: $black;
	width: 100%;
	border-radius: 10px;
}

.lit-test-status {
	float: left;
	display: flex;
	align-items: center;
}

.lit-see-result-fixed-width {
	justify-content: center;
	white-space: nowrap;
}

.lit-View_tests {
	display: flex;

	.lit-dropdown_filter {
		margin-right: 10px;

		.lit-input__field {
			border-radius: 20px;
		}
	}

	.Select_group-function {
		margin-right: 10px;

		.lit-input__field {
			border-radius: 20px;
		}
	}
}

.lit-input__groupname-label {
	margin-top: 30px;
	margin-bottom: 20px;
	margin-right: 10px;
}

.lit-new-test {
	&__wrapper {
		width: 100%;
	}
}

.lit-deleteAll {
	display: flex;
	margin-top: 10px;
	justify-content: center;
}

.lit-menu-item-headings {
	display: inline-block;
	margin-left: 10px;
}

.lit-input_checkbox {
	transform: scale(1.5);
}

.bg-negative1 {
	background-color: $pink;
	color: $white !important;
}

.bg-negative2 {
	background-color: $pink;
	color: $white !important;
	margin-left: 10px;
}

.bg-secondary-green {
	background-color: #7cf1b2;
	color: black;

	/* animation: glowing 2300ms infinite; */
}

.bg-primary2 {
	background-color: rgb(226, 46, 46);
	color: white;
	animation: glowing 1300ms infinite;
}

.bg-primary2-disbaled {
	background-color: rgb(228, 26, 60);
	color: white;
}

.bg-primary2:hover {
	background-color: rgb(228, 24, 24);
	color: white;
	opacity: 5;
}

.bg-primary3 {
	margin-left: 10px;
	background-color: rgb(71, 189, 243);
	color: white;
}

.bg-primary6 {
	margin-left: 10px;
	background-color: transparent;
	height: 40px;
	margin-top: -4px;
	border: solid thin #d6d2d2;
	color: white;
}

.bg-primary4 {
	margin-left: 10px;
	background-color: rgb(243, 71, 80);
	color: white;
}

.bg-primary5 {
	margin-left: 10px;
	background-color: rgb(43, 133, 25);
	color: white;
}

.bg-primary9 {
	background-color: rgb(255, 255, 255);
}

.bg-primary3:hover {
	margin-left: 10px;
	background-color: rgb(24, 157, 219);
	color: white;
}

.bg-primary6:hover {
	margin-left: 10px;

	border-width: 0.1em;
	color: white;
}

.lit-tf_search {
	::placeholder {
		color: rgb(83, 66, 66);
		opacity: 1;
	}
	box-shadow: 0 0 3px 1px rgb(221, 223, 222);
	border: none;
	border-radius: 20px;
	margin-right: 10px;
	background-color: white;
	color: black;
	font-size: 14px;
	text-align: center;
}

.lit-main-candidates-contianer {
	background-color: $cyan;
	border-radius: 10px;
	margin-top: 8px;
	width: 100%;
}

.lit-tf_className {
	display: inline-block;
	align-items: right;
	border: 2px solid rgb(115, 201, 241);
	border-radius: 3px;
	margin-top: 10px;
	margin-left: 5px;
	margin-right: 10px;
	height: 31px;
	width: 150px;
	background-color: white;
	color: black;
	font-size: 15px;
	text-align: left;
}

.lit-select_className {
	display: inline-block;
	align-items: right;
	border: solid rgb(115, 201, 241);
	border-width: 0.1em;
	border-radius: 3px;
	margin-top: 10px;
	margin-left: 5px;
	margin-right: 10px;
	height: 40px;
	width: 200px;
	background-color: white;
	color: black;
	font-size: large;
	text-align: center;
}

.bg-primary2:disabled,
.bg-primary2[disabled] {
	border: 1px solid #ffffff;
	background-color: #d6d2d2;
	color: #faf8f8;
}

.bg-primary3:disabled,
.bg-primary3[disabled] {
	background-color: rgba(180, 223, 242, 255);
	color: #faf8f8;
}

.lit_confirm-cost {
	margin-top: 10px;
}

.lit-new-subscription {
	border-radius: $borderRadius;
	display: inline-block;
	vertical-align: top;
	max-width: 700px;

	padding: $space-m $space-lg;
	/* margin-bottom: $space; */
	text-align: left;
	background-color: $white;
	box-shadow: $boxShadow1;
	min-width: 340px;
	margin-top: 15px;
	margin-right: 10px;

	&__pricing {
		animation: fade 1s;

		& .pricing__header {
			margin-bottom: $space-sm;
		}

		& .pricing__table {
			font-size: $small;
			margin-bottom: 1em;

			& .pricing__item {
				padding-right: $space-sm;
				font-weight: bold;
			}
		}
	}

	&--subscription {
		margin-top: $space-lg;
	}

	&__description {
		a {
			color: royalblue;
		}
	}

	@media screen and (max-width: 1200px) {
		& {
			margin-top: $space-lg;
		}
	}
}

.tickmark {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 65px;
}

.crossmark {
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 65px;
}

.lit-first-test-table {
	width: 100%;
	border-spacing: 0;
	border-bottom: 3px solid $white;
	border-radius: 10px;
	border-top: 3px solid $cyan;
	background-color: $cyan;
}

.lit-mytests-header-mytest-btn-primary {
	border-radius: 20px;
	display: flex;
	color: #353535;
	border: 2px solid transparent;
	font-weight: 500;
	font-family: 'Lato';
	padding: 1px;
	margin-right: 10px;
	background-color: $lightGreen;
}

.lit-mytests-header-mytest-btn-primary:disabled,
.lit-mytests-header-mytest-btn-primary[disabled] {
	border: 1px solid #999999;
	background-color: #a3a2a2;
	color: #ffffff;
	cursor: not-allowed;
}

.lit-mytests-header-mytest-btn-secondary {
	border-radius: 20px;
	display: flex;
	color: #353535;
	border: 2px solid transparent;
	font-weight: 500;
	font-family: 'Lato';
	padding: 1px;
	margin-right: 10px;
	background-color: $cerulean;
}

.lit-mytests-header-mytest-btn-secondary:disabled,
.lit-mytests-header-mytest-btn-secondary[disabled] {
	border-radius: 20px;
	display: flex;
	color: #353535;
	border: 2px solid transparent;
	font-weight: 500;
	font-family: 'Lato';
	padding: 1px;
	margin-right: 10px;
	background-color: $white;
	opacity: 0.6;
	cursor: not-allowed;
}

.lit-mytests-header-mytest-btn-ternary {
	border-radius: 20px;
	display: flex;
	color: #353535;
	border: 2px solid transparent;
	font-weight: 500;
	font-family: 'Lato';
	padding: 1px;
	margin-right: 10px;
	background-color: $pink;
}

.lit-mytests-header-mytest-btn-ternary2 {
	border-radius: 20px;
	display: flex;
	color: #353535;
	border: 2px solid transparent;
	font-weight: 500;
	font-family: 'Lato';
	padding: 1px;
	margin-right: 10px;
	background-color: $orange;
}

.lit-icon-btns {
	background-color: white;
	border-radius: 20px;
	margin-right: 5px;
	padding: 10px;
}

.lit-icon-btns:disabled,
.lit-icon-btns[disabled] {
	border: 1px solid #fffafa;
	background-color: #a1a1a1;
	color: #e6e3e3;
}

.lit-btns-txt {
	line-height: 0;
	margin-right: 3px;
}
.lit-btns-txt-negative {
	line-height: 0;
	margin-right: 3px;
	color: white;
}

.lit-mytests-header-mytest-icon-btn-primary {
	border-radius: 25px;
	margin-right: 5px;
	color: #353535;
	border: 2px solid transparent;
	background-color: $green;
	height: 40px;
}

.lit-mytests-header-mytest-icon-btn-secondary {
	border-radius: 25px;
	margin-right: 5px;
	color: #353535;
	border: 2px solid transparent;
	background-color: $blue;
	height: 40px;
}

.lit-mytests-header-mytest-icon-btn-secondary[disabled] {
	border-radius: 25px;
	margin-right: 5px;
	color: #353535;
	border: 2px solid transparent;
	background-color: $blue;
	height: 40px;
	opacity: 0.5;
}

.lit-mytests-header-mytest-icon-btn-ternary {
	border-radius: 25px;
	margin-right: 5px;
	color: #353535;
	border: 2px solid transparent;
	background-color: $red;
	height: 40px;
	color: $white;
}

.lit-mytests-header-mytest-icon-btn-icon {
	padding: 5px;
}

.lit-fill-out-info-consent {
	margin-left: -3px;
	display: flex;
	align-items: center;
}

.lit-candidate-pin-div {
	background-color: red;
}

.lit-trashed-tests-lists {
	.lit-test__col,
	.lit-test__header {
		padding: 10px 5px;
		width: 2000px;
	}
	.lit-test__actions_header {
		padding: 10px 5px;
		color: $white;
		width: 2000px;
	}
	.lit-test__actions {
		padding: 10px 0px;
		color: $white;
		width: 2000px;
		text-align: center;
	}
}

.lit-model-no-tests-available-text {
	background-color: $orange;
	padding: 1px 10px 1px 2px;
	border-radius: 0px 10px 0px 10px;
	opacity: 0.8;
	margin-top: 10px;
	color: rgb(0, 0, 0);
	padding: 20px;
}

.loader {
	border: 5px solid #f3f3f3;
	border-top: 5px solid #3498db;
	border-right: 5px solid #3498db;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	animation: spin 0.7s linear infinite;
	margin: 0 auto;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.lit-start-test-btn {
	display: inline-block;
	padding: 0.5em 1.2em;
	font-size: 12px;
	line-height: 1.4;
	border: 2px solid transparent;
	border-radius: 3px;
	letter-spacing: 0.015em;
	font-family: 'Lato';
	color: black;
	cursor: pointer;
}

.link-visited {
	background-color: #b0b0b0;
}
.link-not-visited {
	background-color: #7cf1b2;
}

.lit-test-form-span {
	display: inline-block;
	margin-top: 5px;
	text-wrap: nowrap;
}

.lit-test-form-pincode {
	display: inline-block;
	margin-top: 5px;
	font-weight: bold;
}

.lit-pincode-div {
	margin-left: 10px;
	height: 40px;
	width: 93px;
	padding: 0.3em;
	font-size: $p;
	text-align: center;
	letter-spacing: 0.015em;
}
