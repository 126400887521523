@import '../../../styles/common';
.lit_Invoices_table-wrapper {
	margin-top: 50px;
}

.lit-Invoices-header_grid {
	display: flex;
	justify-content: center;
	background-color: #0e688f;
	border-width: 2px 2px 0px 2px;
	color: black;
	justify-content: left;
	align-items: left;
	top: 0;
	position: sticky;
	border-radius: 10px;
}
.lit_input-filters-wrapper-invoices {
	display: flex;
	height: 40px;
	margin-top: 20px;
	margin-bottom: -20px;

	.lit-input__field2 {
		width: 300px;
		margin-left: 5px;
		overflow: hidden;
		font-size: 16px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;

		background-color: #fff;
		height: 100%;
	}
	.lit-input-userActivity_searchUser {
		height: 100%;
		float: right;
		width: 230px;
		margin-left: 10px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;
	}
}
.lit-myClass-header-wrapper {
	display: flex;
	width: 100%;
}
.lit-invoices_grid {
	display: flex;
	cursor: pointer;
	justify-content: left;
	max-height: 40px;
	color: #555555;
	font-size: 14px;
	.bg-primary6 {
		border: none;
	}
}
.lit-invoice-tableRows-scroll {
	min-width: 1120px;
}
.lit-invoices_grid:hover {
	cursor: pointer;
	max-height: 40px;
	background-color: hsl(206, 51%, 46%);
	color: white;
	.bg-primary6 {
		color: white;
	}
}
.lit-invoices_grid-active {
	display: flex;
	min-width: 1120px;
	justify-content: left;
	cursor: pointer;
	max-height: 40px;
	background-color: rgb(234, 238, 241);
	padding: 3px;
	border-bottom: none;
	border-width: 1.5px 1.5px 0.5px 1.5px;
	color: #555555;
	font-size: 14px;
	top: 0;
	position: sticky;
	.bg-primary6 {
		border: none;
		color: black;
	}

	justify-content: center;
}

.lit-invoive-table-header {
	text-align: left;
	min-width: 18%;
	justify-content: left;
	align-items: left;
	word-wrap: break-word;
	padding: 8px 0px 3px 3px;
	color: white;
	margin: 0 1px 0 1px;
}

.lit-summary_EmptyButton-table-header {
	text-align: left;
	min-width: 140px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 140px;
	padding: 8px;
}

.lit-invoice-table-cell {
	text-align: left;
	min-width: 18%;
	justify-content: left;
	align-items: left;
	word-wrap: break-word;
	padding: 8px 0px 8px 3px;
	border-right: 2px solid #f7f7f7;
	margin: 0 1px 0 1px;
}

.lit-summary_EmptyButton-table-cell {
	text-align: left;
	min-width: 140px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 140px;
	padding: 8px;
}
.lit-summary_btn-cell {
	margin-left: auto;
}

.lit-testActivity_selectDate {
	margin-left: 0px;
}

.lit-admin-invoice {
	&__wrapper {
		width: 100%;
	}
}

.lit-main-invoice-contianer {
	background-color: #f0f9fc;
	border-radius: 25px;
	padding: 3px;
	margin-top: 8px;
	height: 70vh;
	overflow-x: auto;
	width: 100%;
}

.lit_myClass-invoice-table-wrapper {
	margin-top: 10px;
	padding: 5px;
}

.lit-dropdown_filter {
	margin-right: 10px;
}
