.lit-sidebar {
	position: fixed;
	left: 0;
	top: 0;
	width: $sidebarWidth;
	height: 100vh;
	z-index: $zSidebar;
	color: $black;
	background-color: white;

	& .lit-logo {
		height: $headerHeight;
		overflow: hidden;
		box-sizing: border-box;
		margin-left: 0px;
	}
	&.clickable:hover {
		cursor: pointer;
	}
}
