.lit-login__action-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	.lit-login__proceed_btn {
		flex-shrink: 0;
		.bg-custom {
			background-color: rgb(33, 136, 33);
			color: white;
		}
	}
	.lit-login__cancel_btn {
		flex-shrink: 0;
		margin-left: 1em;

		.bg-custom {
			background-color: rgb(209, 32, 32);
			color: white;
		}
	}
}
.lit-change-login-error {
	background-color: white;
	padding: 3rem;
}
