@import '../../styles/common';

.lit-modal-informationPane {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	height: 100vh;
	width: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	z-index: 80;
	animation: fade 0.5s;
}

.lit-modal-pane {
	background: white;
	padding: 10px;
	max-width: 40%;
	margin: 15% auto;
	animation: slideUp 0.7s;
	border: 4px solid $orange;
	border-radius: 25px;
	color: $black;
}

.lit-option-pane-container {
	padding: 35px;
}

.lit-options-container {
	display: flex;

	.lit-option-pane {
		padding: 1px;
		border-radius: 5px;
		width: 7rem;
		margin-right: 10px;

		.__approve {
			margin: auto;
			background-color: $lightGreen;
			color: $white;
			border: 1px solid $lightGreen;
			font-size: medium;
			font-family: 'Lato';
			width: 100%;
			height: 30px;
			border-radius: 5px;
			opacity: 0.8;
		}

		.__approve:hover {
			margin: auto;
			background-color: $lightGreen;
			color: $white;
			border: 1px solid $lightGreen;
			font-size: medium;
			font-family: 'Lato';
			width: 100%;
			height: 30px;
			border-radius: 5px;
			opacity: 1;
		}

		.__disprove {
			margin: auto;
			background-color: $red;
			color: $white;
			border: 1px solid $red;
			font-size: medium;
			font-family: 'Lato';
			width: 100%;
			height: 30px;
			border-radius: 5px;
			opacity: 0.8;
		}

		.__disprove:hover {
			margin: auto;
			background-color: $red;
			border: 1px solid $red;
			font-size: medium;
			font-family: 'Lato';
			width: 100%;
			height: 30px;
			border-radius: 5px;
			opacity: 1;
			color: $white;
		}
	}
}
