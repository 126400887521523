@import '../../../styles/common';

.lit-mytests-candidate-edit-class {
	display: flex;
}

.lit-test__col-render {
	padding: 10px 0px 10px 10px;
	color: #fff;
	min-width: 129px;
	background-color: $white;
	text-align: left;
}

.lit-test__col-render-header {
	padding: 10px 0px 10px 10px;
	color: #fff;
	min-width: 129px;
	background-color: $cerulean;
	text-align: left;
	border-radius: 10px;
}
