.lit-registration-view {
	height: 100vh;

	.lit-view__content {
		background-color: #0f4256;
		height: 100%;
		border-radius: 25px 0 0 0;
		margin-left: 200px;
	}
}
