.datetime-input {
	width: 100%;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 14px;
	margin-bottom: 15px;
	outline: none;
	box-sizing: border-box;
}

´ .datetime-input:focus {
	border-color: #007bff;
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
