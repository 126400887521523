@import '../../styles/common';
.lit-all-tests {
	.lit_txtf {
		width: 100%;
		padding: 2px 20px;
		margin: 6px 0 8px 0;
		position: relative;
		display: inline-block;
		border: none;
		background-color: transparent;
		border-radius: 4px;
		box-sizing: border-box;
	}
	.month {
		margin: $space-lg 0 $space-xlg;

		th {
			text-align: left;
			//padding: 0 $space;
		}

		td {
			text-align: left;
		}
	}

	.lit-list {
	}

	.score {
		margin: 0;
	}
}
