.lit-event-log {
	background-color: #f0f9fc;
	border-radius: 25px 0 0 0;
	margin-left: -65px;
	padding: 10px;

	td {
		border-bottom: solid 1px #ccc;
		padding: 4px;
	}
}

.inline-btn {
	display: flex;
	margin-right: 5px;
	height: 40px;
	margin-bottom: 5px;
	h3 {
		margin-right: 5px;
		margin-top: 3px;
	}
}
