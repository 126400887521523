@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

.lit-customLogin {
	margin: auto;
	width: 90%;
	border-radius: 15px;

	.lit-customLogin-title {
		color: #ffa500;
	}

	&__wrapper {
		background: white;
		border-radius: 20px;
		padding: 20px 10px;
		margin: auto;
		position: relative;
		width: 450px;
	}
	.lit-screen__content {
		z-index: 1;
		position: relative;
		height: 70%;
		border: solid #ffa500 thin;
		border-radius: 10px;
		padding: 10px 15px;
		margin-top: -10px;
	}
	.lit-screen__background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		-webkit-clip-path: inset(0 0 0 0);
		clip-path: inset(0 0 0 0);
	}

	//background-color: rgba(255, 255, 255, 0.938);
	//width: 100%;
	//height: 100%;

	.lit-invlaid-status-container {
		width: 79%;
		margin: auto;
		background-color: yellow;
		color: white;
		border: 1 thin yellow;
		border-radius: 1rem;
		.lit-invlaid-note {
			color: rgb(207, 25, 25);
			text-align: center;
		}
	}
	.lit-valid-change-container {
		width: 79%;
		margin: auto;
		background-color: cyan;

		margin-bottom: -1em;
		border: solid 1px rgb(68, 107, 8);
		border-radius: 1rem;
		.lit-valid-change {
			color: green;
			text-align: center;
		}
	}
	.lit-valid-timeout-container {
		justify-content: center;
		margin: auto;
		padding: 2px;
		background-color: rgb(241, 243, 112);
		color: red;

		.lit-valid-timeout {
			color: red;
			text-align: center;
			font-size: 18px;
		}
	}
	.lit-reset-info-container {
		width: 90%;
		margin-top: 2em;
		margin-bottom: 1em;
		border: thin 3px yellow;
		border-radius: 1rem;
		.lit-reset-info {
			font-size: 17px;
			color: rgb(0, 250, 250);
		}
	}
	.login__icon {
		position: absolute;
		top: 30px;
		color: #7875b5;
	}
	.lit-customLogin-wrapper-self {
		margin: auto;
		margin-top: 10%;
		width: 50%;
		border: 5px solid #1c7592;
		border-radius: 15px;
		padding: 2.5rem;
		background-color: #1d6c85;
		min-height: 200px;
		min-width: 400px;

		.lit-customLogin-title {
			color: white;
		}
	}

	.lit-customPin-wrapper {
		margin: auto;
		margin-top: 10%;
		width: 100%;
		border: 5px solid #00080a;
		border-radius: 1rem;
		padding: 2.5rem;
		background-color: #2ca8ce;
		min-height: 200px;
		min-width: 400px;
		.lit-customLogin-title {
			color: white;
		}
	}
	.lit-pin-instrucitons {
		padding: 0.1em;
		word-wrap: break-word;
		width: 70%;
		color: black;
	}
	.lit-customLogin-form-wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		min-width: 200px;
		/* background-color: rgb(119, 133, 170); */
		color: black;
	}
	.lit-customPin-inner-content {
		margin: auto;
	}
	.lit-customLogin-inner-content {
		margin: auto;
		width: 70%;
	}
	.lit-compound-fields {
		margin: 1px;
	}
	.lit-compound-pin-fields {
		margin: auto;
		width: 60%;

		padding: 10px;
	}
	.lit-label {
		display: block;
		margin: auto;
		padding: 10px 0;
		min-width: 90%;
		color: #ffa500;
		box-sizing: border-box;

		font-family: Raleway, sans-serif;
	}
	.lit-label-pin {
		.customLogin-pin-tf {
			min-width: 50%;
			margin-top: 0.5rem;
			min-height: 2rem;

			border-radius: 10px;
			border: thin solid rgb(172, 34, 34);
			border-radius: 20px;
			margin: 5px;
			padding-left: 1.2em;
		}
	}
	.lit-login__icon {
		position: absolute;
		top: 55px;
		left: 25px;
		color: #43bbec;
	}
	.lit-label-usr {
		.customLogin-user-tf {
			/* border: none;
			border-bottom: 2px solid #d1d1d4;
			background: none;
			padding: 10px;
			color: white;
			background-color: transparent;
			font-weight: 700;
			width: 75%;
			transition: 0.2s;
			font-family: Raleway, sans-serif; */
			background-color: #edf5f8;
			min-width: 100%;
			margin-top: 0.5rem;
			min-height: 2rem;
			border: 1px solid #d4d3e8;
			border-radius: 8px;
			padding-left: 2em;
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: rgb(194, 197, 185);

			font-family: Raleway, sans-serif;
		}
	}

	.lit-lastName__icon {
		position: absolute;
		top: 135px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-phone__icon {
		position: absolute;
		top: 222px;
		left: 22px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-psw__icon {
		position: absolute;
		top: 135px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-psw-reg__icon {
		position: absolute;
		top: 295px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-psw-confirm__icon {
		position: absolute;
		top: 374px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-psw-confirm-low__icon {
		position: absolute;
		top: 393px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-invalid-email-note {
		max-width: 100%;
		margin-top: -0.3rem;
		font-size: 16px;
		word-wrap: break-word;
		color: #da0000;
		text-align: center;
	}
	.lit-psw-new__icon {
		position: absolute;
		top: 37px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-psw-new-confirm__icon {
		position: absolute;
		top: 108px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}
	.lit-psw-new-confirm-str__icon {
		position: absolute;
		top: 128px;
		left: 25px;
		z-index: 10001;
		color: #43bbec;
	}

	.lit-label-psw {
		.customLogin-psw-tf {
			/* border: none;
			border-bottom: 2px solid #d1d1d4;
			background: none;
			padding: 10px;
			color: white;
			font-weight: 700;
			width: 75%;
			transition: 0.2s;
			font-family: Raleway, sans-serif; */
			border: 1px solid #d4d3e8;

			min-width: 100%;
			background-color: rgb(237, 245, 248);
			margin-top: 0.5rem;
			min-height: 2rem;
			z-index: 10000;
			border-radius: 8px;

			padding-left: 1.8em;
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: rgb(194, 197, 185);
			opacity: 1; /* Firefox */
			font-family: Raleway, sans-serif;
		}

		/* .customLogin-psw-tf:active,
		.customLogin-psw-tf:focus,
		.customLogin-psw-tf:hover {
			outline: none;
			border-bottom-color: #b1e02d;
		} */
	}
	.Mui-focused {
		border: thin white;
		min-width: 100%;
		background-color: rgb(237, 245, 248);
		margin-top: 0.5rem;
		min-height: 2rem;
		border: thin white;
		z-index: 10000;
		border-radius: 8px;

		padding-left: 1.8em;
	}
	.lit-reset-psw-container {
		text-align: center;
		.lit-reset-psw {
			text-indent: 1rem;
			color: #0834c7;
			font-size: 13px;
			margin-bottom: 10px;
		}
		.lit-reset-psw:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.lit-btn-submit-wrapper {
		text-align: center;
		width: 300px;
		.lit-btn-submit {
			color: black;
			background: #fff;
			font-size: 12px;
			padding: 10px;
			border-radius: 8px;
			border: 1px solid #d4d3e8;
			text-transform: uppercase;
			font-weight: 700;
			display: flex;
			align-items: center;
			width: 40%;
			color: #43bbec;
			cursor: pointer;
			transition: 0.2s;
		}
		.lit-btn-submit:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
		.lit-btn-submit:hover {
			color: #43bbec;
			border: 1px solid #ffa500;
			opacity: 0.7;
			cursor: pointer;
		}
	}
}

.loading-indicator {
	display: flex;
	align-items: center;
	gap: 8px;
}

.spinner {
	width: 16px;
	height: 16px;
	border: 3px solid #ffffff;
	margin-left: 10px;
	border-top: 3px solid #43bbec;
	border-radius: 50%;
	animation: spin 0.5s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lit-psw-strength-container {
	display: flex;
	flex-direction: row;
	min-height: 20px;
	width: 100%;
	.lit-str-text {
		margin-top: 3px;
		max-width: 70%;
		margin-left: 5px;
		float: right;
		color: rgb(12, 241, 203);
		font-size: 0.7em;
	}
	.lit-strength-Exhibitor-green {
		width: 95%;
		height: 3px;
		margin-top: 7px;
		background-color: green;
		transition: 0.5s;
		border: thin solid;
		border-radius: 4px;
		border-color: white;
		padding: 2px;
	}
	.lit-strength-Exhibitor-orange {
		margin-top: 7px;
		width: 55%;
		height: 5px;
		background-color: rgb(230, 178, 82);
		bottom: -8px;
		transition: 0.5s;
		border: thin solid;
		border-radius: 4px;
		border-color: white;
		padding: 2px;
	}
	.lit-strength-Exhibitor-red {
		margin-top: 7px;
		width: 35%;
		height: 5px;
		background-color: red;
		bottom: -8px;
		transition: 0.5s;
		border: thin solid;
		border-radius: 4px;
		border-color: white;
		padding: 2px;
	}
}
.lit-signin__icon {
	margin-left: 10px;
}
.lit-log-gram-wrapper {
	margin-bottom: -45px;
	margin-top: 15px;
	text-align: center;
}
.lit-fav-logo {
	margin-top: 20px;
	&__img {
		max-height: 17px;
	}

	&.clickable:hover {
		cursor: pointer;
	}
}
.lit-btn-link-resend-wrapper {
	display: flex;
	flex-direction: column;
	.lit-btn-link-resend {
		color: black;
		background: #fff;
		font-size: 14px;
		padding: 10px;
		border-radius: 8px;
		border: 1px solid #d4d3e8;
		text-transform: uppercase;
		font-weight: 700;
		display: flex;
		align-items: center;
		width: 30%;
		color: #43bbec;
		cursor: pointer;
		transition: 0.2s;
		margin-top: 10px;
	}
	.lit-btn-link-resend:disabled {
		background-color: #cccccc;
		color: #666666;
		cursor: not-allowed;
		opacity: 0.6;
		pointer-events: none;
	}
	.lit-btn-link-resend:hover {
		border: 1px solid #ffa500;
		opacity: 0.7;
	}
}

.lit-btn-link-resend-text {
	color: #0834c7;
}
