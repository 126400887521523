@import '../../styles/common';

.lit-view {
	.lit-subscriptions {
		margin-top: -77px;
		margin-left: -55px;

		nav {
			position: relative;
			display: flex;
		}

		nav a {
			position: relative;
			margin: 0 10px;
			font-size: 20px;
			color: $white;
			font-family: 'Trebuchet MS', sans-serif;
			text-decoration: none;
		}

		nav #marker-default {
			position: absolute;
			left: 10px;
			height: 4px;
			width: 186px;
			background: $orange;
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}

		nav #marker-information {
			position: absolute;
			left: 216px;
			height: 4px;
			width: 236px;
			background: $orange;
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}

		nav #marker-groups {
			position: absolute;
			left: 473px;
			height: 4px;
			width: 75px;
			background: $orange;
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}

		.info {
			margin: 10px (-$space-sm);
			display: flex;
			width: 100%;
			/* flex-wrap: wrap; */

			&__col {
				display: inline-block;

				vertical-align: top;
				padding: $space-m $space-lg;
				text-align: center;
				background-color: $white;
				box-shadow: $boxShadow1;
				flex-wrap: wrap;
				flex-shrink: 0;
				width: 100%;
				border: 3px solid $lightGreen;
				border-radius: 25px;
				@include stagger(fade, 4, 0.4);
			}

			&__heading {
				margin: 0 0 $space-sm;
			}

			&__text {
				&.large {
					margin: 0;
					font-size: $h3;
					font-weight: bold;
				}
			}

			&__status {
				// padding: 4px 0 0;

				.lit-test-status__indicator {
					margin-left: -5px;
				}

				.lit-test-status__label {
					color: $textColor;
					font-size: $h3;
					font-weight: bold;
				}
			}

			&__extra {
				margin-top: $space-sm;
				font-size: $small;

				span {
					line-height: 33px;
					color: $gray;
				}
			}
		}

		.see-more {
			margin-top: $space-xlg;
			min-width: 230px;
			color: $black;

			&:not(.bg-border) {
				border: 1px solid transparent;
			}
		}
	}
}

.checkout {
	border-bottom: solid rgba(29, 106, 173, 255);
	padding: 1%;

	&__expanded {
		opacity: 0;
		animation: registerExpand 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.05);
		animation-fill-mode: forwards;
		animation-delay: 0.1s;
		transform-origin: top;
	}
}

.lit_eidt-btnGrid {
	margin-top: 10px;
}

.lit_assign-permission {
	display: inline-block;
	float: left;
}

.add-language_bg-secondary {
	height: 45px;
	background-color: #43bbec;
	color: white;
	margin-top: 10px;
	padding-bottom: 10px;
	margin-left: 15px;
	text-align: left;
	text-decoration: whitesmoke;
}

.lit_delete-permission {
	display: inline-block;
}

.delete-permission_bg-secondary {
	height: 45px;
	background-color: #c41212;
	color: white;
	margin-top: 5px;
	padding-bottom: 7px;
	text-align: left;
	text-decoration: whitesmoke;
	margin-left: 7px;
}

.lit-new-subscriptions {
	border-radius: $borderRadius;
	display: inline-block;
	vertical-align: top;
	max-width: 700px;
	padding: $space-m $space-lg;
	/* margin-bottom: $space; */
	text-align: left;
	background-color: $white;
	box-shadow: $boxShadow1;
	min-width: 340px;
	margin-top: 15px;
	margin-right: 10px;
}

.lit-button-grid {
	display: inline-block;
	margin-right: -35px;
	vertical-align: left;
	width: 100%;
	box-sizing: border-box;

	.movetoleft {
		margin-left: 10px;
	}

	.lit_show_Subscripton {
		margin-left: 10px;
	}
}

.lit-main-table {
	margin-top: -2px;
	border: solid black;
	border-top: none;
	border-right: none;
	border-width: 2px;
}

.lit-button-header {
	display: flex;
	min-width: 1040px;
	align-items: left;
	justify-content: left;
	box-sizing: border-box;
}

.lit-subscription-table-container {
	width: 100%;
}

.lit_tables_container {
	margin-top: 10px;
	display: inline-block;
	float: left;
	width: 100%;

	.lit-main-master-header {
		display: flex;
		border-top: solid black;
		border-left: solid black;
		border-right: solid black;
		border-bottom: none;
		border-width: 2px;
		color: black;
		background-color: $orange;

		.lit-main-language-header {
			min-width: 85px;
			max-width: 85px;
			text-align: center;
			border-right: solid black;
			border-width: 2px;
		}

		.lit-main-production-header {
			min-width: 522px;
			text-align: center;
			border-right: solid black;
			border-width: 2px;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		.lit-main-norming-header {
			min-width: 354px;
			text-align: center;
			border-right: solid black;
			border-width: 2px;
			font-weight: bold;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		.lit-main-basic-header {
			min-width: 86px;
			text-align: center;
			border-right: solid black;
			border-width: 2px;
			font-weight: bold;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		.lit-admin-basic-header {
			min-width: 86px;
			text-align: center;
			border-right: none;
			font-weight: bold;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		.lit-main-final-header {
			min-width: 101px;
			text-align: center;
			font-weight: bold;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
	}

	.lit-main-secondary-header {
		display: flex;
		border: solid black;
		background-color: $orange;
		color: black;
		border-width: 2px;

		.lit-main-subHeaders.language {
			min-width: 85px;
			max-width: 85px;
			border-right: solid black;
			border-width: 2px;
			display: inline-block;

			.lit-secondary-headings {
				font-weight: bold;
			}
		}

		.lit-main-subHeaders.production_child {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			word-wrap: break-word;
			align-items: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				font-size: 12px; //checkout
				margin-top: 10px;
			}
		}

		.lit-main-subHeaders.production_youth_screening {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px; //checkout
			}
		}

		.lit-main-subHeaders.production_youth_followup {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px;
			}
		}

		.lit-main-subHeaders.production_adult_screening {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px; //checkout
			}
		}

		.lit-main-subHeaders.production_adult_followup {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px; //checkout
			}
		}

		.lit-main-subHeaders.norming_child {
			min-width: 118px;
			max-width: 118px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px; //checkout
			}
		}

		.lit-main-subHeaders.norming_youth {
			min-width: 118px;
			max-width: 118px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px; //checkout
			}
		}

		.lit-main-subHeaders.norming_adult {
			min-width: 118px;
			max-width: 118px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
				font-size: 12px; //checkout
			}
		}

		.lit-main-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: transparent;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		.lit-admin-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			text-align: center;
			background-color: transparent;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		.lit-main-subHeaders.final {
			min-width: 80px;
			max-width: 80px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: transparent;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
	}

	.lit-main-tertiary-header {
		display: flex;
		border: solid black;
		border-top: none;
		border-width: 2px;
		color: black;

		.lit-main-subHeaders.language {
			min-width: 85px;
			max-width: 85px;
			border-right: solid black;
			border-width: 2px;
			color: black;
			background-color: $orange;
			opacity: 0.7;

			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 13px;
				margin-left: 15px;
			}
		}

		.lit-main-subHeaders.production_child {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			color: black;
			opacity: 0.7;

			word-wrap: break-word;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.production_youth_screening {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.production_youth_followup {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			background-color: $orange;
			color: black;
			text-align: center;
			opacity: 0.7;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.production_adult_screening {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.production_adult_followup {
			min-width: 87px;
			max-width: 87px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.norming_child {
			min-width: 118px;
			max-width: 118px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.norming_youth {
			min-width: 118px;
			max-width: 118px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.norming_adult {
			min-width: 118px;
			max-width: 118px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-main-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			border-right: solid black;
			border-width: 2px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
				font-size: 12px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}

		.lit-admin-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
			}
		}

		.lit-main-subHeaders.final {
			min-width: 105px;
			max-width: 120px;
			text-align: center;
			background-color: $orange;
			opacity: 0.7;

			color: black;

			.lit-secondary-headings {
				font-weight: normal;
			}
		}
	}
}

.lit-subscriptions {
	.lit-main-table {
		tr.lit-list__item {
		}

		td.lit-list__col {
			border-right: 2px solid black;

			padding-bottom: 0;

			.lit-list__text.language {
				padding: none;
				text-align: center;
				max-width: 100%;
				min-width: 74px;
				min-height: 43px;
				border-left: none;
				margin-left: -11px;
			}

			.lit-list__text.final {
				padding: none;
				margin: none;
				box-sizing: none;
				text-align: center;
				max-width: 85px;
				min-width: 85px;
			}
		}
	}
}

.lit-table_data {
	font-size: 15px;
	font-weight: bold;
}

.previous-tests {
	margin-top: 20px;
	float: left;

	&__heading {
		animation: fade 0.4s;
	}
}

.lit_deleted-tests {
	margin-top: 20px;
	float: left;

	&__heading {
		animation: fade 0.4s;
	}
}

.detailed-subscription {
	margin-top: 20px;
	float: left;
	margin-left: 10px;
	width: auto;

	&__heading {
		animation: fade 0.4s;
	}
}

.lit-input.inline .lit-input__label.wide {
	min-width: 120px;
	margin-left: 8px;
}

.tab-heading {
	margin-top: 20px;
	font-family: 'Lato';
}

.tickmark {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 65px;
}

.tickmark.norming {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 96px;
}

.crossmark {
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 65px;
}

.crossmark.norming {
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 96px;
}

.tickmark.basic {
	background-color: transparent;

	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 64px;
}

.tickmark-admin.basic {
	background-color: transparent;

	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 66px;
}

.crossmark.basic {
	background-color: transparent;
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 64px; //66px
}

.crossmark-admin.basic {
	background-color: transparent;
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 66px;
}

.tables {
	display: inline-block;
	float: left;
}

//for Edit subscription

.one_main-options {
	margin-top: 7px;
}

.two-main_options {
	margin-top: 5px;
}

.three-main_options {
	margin-top: 5px;
}

.lang_label {
	font-size: 20px;
	color: black;
}

.user_checkbox {
	margin-top: 10px;
	transform: scale(1.4);
}

.lit-newlogin {
	width: 100%;
}

.lit-modal__island-newLogin {
	display: flex;
	background-color: white;

	border: solid thin red;
	height: 60%;
	min-height: 550px;
	min-width: 600px;
	width: 70%;
	padding: 5%;
	margin: 5%;
	margin-left: 18%;
}

.lit-verifyLogin {
	max-width: 50%;
	min-width: 350px;
	height: 50%;
	margin-left: 25%;
	padding: 3%;
	border: solid rgb(240, 235, 235);
	border-radius: 6%;
}

.lit-newLogin__content {
	margin-top: 4rem;
}

.lit-change-loginMethod {
	background-color: rgba(255, 255, 255, 0.438);
	padding: 1rem;
	border: solid 0.2em $lightGreen;
	border-radius: 1rem;
	margin-right: 20px;

	p {
		font-size: 1rem;
	}
}

.lit-subscription-main {
	background-color: $lightBlue;
	border-radius: 25px 0 0 0;
	margin-top: 74px;
	margin-left: -10px;
	padding-left: 20px;
	padding-top: 1px;
	padding-bottom: 20px;
}

.lit-mine-group-table-header {
	text-align: left;
	padding: 10px 10px;
	color: $white;
	font-family: 'Lato';
}

.lit-mine-group-table-header-row {
	background-color: $cyan;
}

.lit-mine-group-main-table {
	width: 100%;
	background-color: $cyan;
	border-spacing: 0;
	border-top: 6px solid $cyan;
	border-radius: 10px;
	padding: 0px;
}

.lit-groups-main {
	margin-right: 20px;
}

.lit-input-save {
	margin-left: 6px;
}
