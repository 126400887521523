.lit-header {
	padding: $space-sm 0;
	// background-color: $pageBackground;
	z-index: $zHeader;
	height: $headerHeight;
	display: flex;
	justify-content: space-between;

	h1 {
		margin: 0;
		margin-left: $space;
	}
}

.lit-logo {
	margin-left: $space;

	&__img {
		padding: 10px;
		margin-top: 13px;
	}
	&.clickable:hover {
		cursor: pointer;
	}
}

.lit-header-info {
	color: $black;
	align-self: center;
	margin-top: -30px;
	&__expires {
		font-size: $small;
	}

	& .user-stage-info-text {
		font-size: $small;
	}

	& .user-stage-indicator .lit-test-status__indicator.small {
		height: 12px;
		width: 12px;
		margin-bottom: 2px;
		vertical-align: middle;
	}
}
