.lit-routes-div {
	background-color: #f0f9fc;
	border-radius: 25px 0 0 0;
	margin-left: -65px;
	padding: 10px;
}

.lit-users-view {
	margin-left: -10px;
	margin-top: -77px;
	padding-bottom: 7px;
	width: auto;
}
