@import '../../styles/common';
.Select_group-function {
	.standard-select_group {
		margin-left: 90px;
		margin-right: 10px;
		width: 7.25em;
		height: 2.25em;
		background: #fff;
	}
}
.Main_user_checkbox {
	margin-left: -28px;
	margin-right: 10px;
	width: 1.25em;
	height: 1.25em;
	background: rgb(46, 41, 41);
}
.Single_user_checkbox {
	margin-left: 8px;
	margin-right: 10px;
	width: 2.25em;
	height: 1.25em;
	background: rgb(46, 41, 41);
}

.lit-address_list {
	font-size: 20px;
}

/* .lit_group-heading {
	margin-top: 20px;
	margin-bottom: -3px;
	display: inline-block;
	//margin-left: 16px;
	width: 0.25em;
	
	border-radius: 4px;
} */
.standar-select_group {
	background-color: transparent;
	border: none;
	padding: 0 1em 0 0;
	margin: 0;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
}

.lit-input_group_field {
	width: 20%;
	border: none;
	height: 80%;
	line-height: 60px;
	background-color: white;
	size: 100;
}
.lit-edit-group-container {
	display: flex;
	float: left;
}
.inline-textFiled2 {
	margin-right: 5px;
	.lit-tf_search {
		height: 38px;
	}
}
.inline-textFiled {
	background-color: transparent;
	color: black;
	width: 185px;
	height: 100%;

	border: solid rgba(49, 123, 156, 255);
	border-width: 0.1em;
}
.Groups {
	.lit_group-heading {
		margin-top: 50px;
		.delete_Group {
			margin-top: 10px;
			.inline-btn {
				display: inline-block;
				margin-right: 5px;
				margin-bottom: 10px;
			}
		}
	}
}
