@import '../../../../styles/common';

.lit-handbook-main {
	display: flex;
	flex-direction: column;
	background-color: $lightBlue;
	margin-left: 10px;
	border-radius: 25px 0 0 0;
	padding: 20px;
	margin-top: 20px;
}

.lit-handbook-main_library-style {
	display: flex;
	flex-direction: column;
	background-color: $lightBlue;
	margin-left: -65px;
	border-radius: 25px 0 0 0;
	padding: 20px;
	margin-top: 20px;
}

.lit-handbook-title-link {
	width: 20%;
	text-align: center;
	text-decoration: underline;
}

.lit-handbook-img {
	margin: 10px;
	width: 20%;
	height: 20%;
}

.lit-handbook-title {
	font-size: 30px;
	margin-left: 8px;
	font-family: 'Lato';
	color: black;
}

.lit-handbook-title > h1 {
	margin: 0;
}
.lit-library-content-wrapper {
	display: table;
	flex-direction: column;
	width: 70%;
	max-width: 850px;
	&__row {
		display: table-cell;
		justify-content: center;
		align-items: center;
		margin: auto;
		img {
			height: 460px;
			width: 340px;
			border: solid 2px #0f4256;
			border-radius: 10px;
		}
	}
}

.lit-lib-handbook-title-link {
	margin-top: -10px;
	text-align: center;
	width: 40%;
	margin-left: 100px;
	text-decoration: underline;
}

.lit-lib-handbook-img {
	margin: 10px;
	width: 300px;
	height: 30%;
}
